import React from "react";
import GoogleMapReact from "google-map-react";
import ReactHtmlParser from "react-html-parser";
import RoomIcon from "@material-ui/icons/Room";
import { Avatar } from "@material-ui/core";

const PropertyMarker = () => (
  <div className="marker">
    <RoomIcon />
  </div>
);

const LocationSection = ({ property }) => {
  const getPropertyLocation = () => {
    return {
      lat: parseFloat(property.location_coords.lat),
      lng: parseFloat(property.location_coords.lng),
    };
  };

  return (
    <div className="location-section">
      <div className="location-wrapper" id="property-location">
        <h3>Where it is located</h3>
        {property &&
          property.macrolocation_heading !== "" &&
          property.macrolocation_description !== "" && (
            <div>
              <h4>{property.macrolocation_heading}</h4>
              {property.macrolocation_image_path !== "" && (
                <img
                  alt="City Picture"
                  src={property.macrolocation_image_path}
                />
              )}
              <div>{ReactHtmlParser(property.macrolocation_description)}</div>
            </div>
          )}
        {property &&
          property.microlocation_heading !== "" &&
          property.microlocation_description !== "" && (
            <div>
              <p>{property.microlocation_heading}</p>
              {property.microlocation_image_path !== "" && (
                <img
                  alt="Neighborhood Picture"
                  src={property.microlocation_image_path}
                />
              )}
              <div>{ReactHtmlParser(property.microlocation_description)}</div>
            </div>
          )}

        {property &&
          property.location_coords &&
          !isNaN(
            property.location_coords.lat && !isNaN(property.location_coords.lng)
          ) && (
            <div
              style={{
                height: "370px",
                width: "100%",
                borderRadius: "12px",
                overflow: "hidden",
              }}
            >
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                defaultZoom={parseInt(process.env.REACT_APP_MAP_ZOOM)}
                center={getPropertyLocation()}
              >
                <PropertyMarker
                  lat={property.location_coords.lat}
                  lng={property.location_coords.lng}
                />
              </GoogleMapReact>
            </div>
          )}
      </div>
    </div>
  );
};

export default LocationSection;
